
.non-hover-team {
  opacity: .1;
}

#ap-chart .click-active-team, .active {
  opacity: 1;
  stroke-opacity: .7;
  stroke: orange;
  stroke: #CA4;
  /*z-index: 1000;*/
  /*r: 8;*/
}

#ap-chart path.click-active {
  stroke-width: 3.0;
}

#ap-chart path.active {
  stroke-width: 6.0;
  stroke-opacity: .7;
}


#ap-chart .axis path,
#ap-chart .axis line {
  fill: none;
  stroke: #1E202C;
  shape-rendering: crispEdges;
}




#ap-chart .y.axis path {
  display: none;
}


#ap-chart .x.axis line {
  display: none;
}

#ap-chart .y.axis line {
  display: none;
}







.tooltip {
  //top: 0 px;
  //left: 0 px;
  position: absolute;
  padding: 10px;
  font: 12px sans-serif;
  background: #1E202C;
  //background: #1E202C;
  color: #fff;
  border: 0px;
  border-radius: 8px;
  pointer-events: none;
  opacity: .78;
  visibility: hidden;
}

.tootip text {
  opacity: 1;
}

.green {
  color: #239D1FFF;
}
.red {
  color: #E12C23FF;
}

.svg-container {
  display: inline-block;
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  vertical-align: middle;
  overflow: hidden;
}

.image {
  filter: url(.grayscale);
}

#ap-chart {
  //background-color: #1E202C;
  background-color: #EEE;
  border-radius: 5px;
  //position: relative;
}




#ap-chart .axis text {
  font: 10px sans-serif;
  fill: #AAA;
}

#ap-chart .x.axis path {
  //stroke: #666;
  stroke: #AAA;
}

.graph-subtitle {
  //fill: #666;
  fill: #AAA;
}

.graph-subtitle text {
  font: 4px sans-serif;
}

.graph-title {
  //color: #c1c4d5;
  fill: #4A4D5D;
  //fill: #1E202C;
  //fill: black;
  font-weight: bold;
  font-size: 30px;
}

//text for axis labels
.graph-axis {
  //color: #1E202C;
  //fill: #ccc;
  fill: #4A4D5D;
  //fill: #333;
  font-weight: 549;
  font-smooth: 2em;
}

.graph-source {
  fill: #AAA;
  //font-weight: 549;
  font-smooth: 2em;
  font-size: 14px;
}

