// Messages
//
// Show alert messages to users. You may add it to single elements like a `<p>`,
// or to a parent if there are multiple elements to show.

.message {
  margin-bottom: 1rem;
  padding: 1rem;
  color: inherit;
  //color: /*$gray-3*/ #060;
  background-color: /*$gray-code*/ #EEE;
  border: 1px solid #DDD;
  padding: .5rem;
  margin-bottom: 1rem;
  font-size: .8rem;
}
