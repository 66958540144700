html {
  background: #f5f7f8;
  font-family: system-ui;
  -webkit-font-smoothing: antialiased;
  padding: 20px 0;
}


.band {
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  
  display: grid;
  
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 20px;
  
  @media (min-width: 30em) {
    grid-template-columns: 1fr 1fr;
  }
  
  @media (min-width: 60em) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.card {
//BACKGROUND COLOR of the card
  background: lighten($side_bg_code_bg, 5%);
  //background: white;
  text-decoration: none;
  border-radius: 5px;
  color: #999;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  min-height: 100%;
  
  // sets up hover state
  position: relative;
  top: 0;
  transition: all .1s ease-in;
    
  &:hover {
    top: -2px;
    //box-shadow: 0 4px 5px rgba(0,0,0,0.2);
    box-shadow: 0 4px 5px $gray-5;
    text-decoration: none;
    background: lighten($side_bg_code_bg, 10%);
  }
  
  article {
    padding: 20px;
    flex: 1;
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  h1 {
    font-size: 16px;
    margin: 0;
    //color: $gold;
    color: #EEE;

  }
  
  p {
    flex: 1;
    line-height: 1.4;
    font-size: 12px;
    color :darken(#999, 15%);
  }
  
  span {
    font-size: 12px;
    font-weight: bold;
    //color: #999;
    color: $gold;
    text-transform: uppercase;
    letter-spacing: .05em;
    margin: 2em 0 0 0;
  }
  
  .thumb {
    padding-bottom: 60%;
    background-size: cover;
    //background-position: center center;
  }
}

.item-1 {
  @media (min-width: 80em) {
    grid-column: span 2;
    
    h1 {
      font-size: 24px;
    }
  }
}