// Code
//
// Inline and block-level code snippets. Includes tweaks to syntax highlighted
// snippets from Pygments/Rouge and Gist embeds.

code,
pre {
  font-family: $code-font-family;
}

code {
  padding: .25em .5em;
  font-size: 85%;
  //color: $code-color;
  color: #fff;
  background-color: $gray-code;
  border-radius: 3px;
}

pre {
  margin-top: 0;
  margin-bottom: 0;
}

pre code {
  padding: 0;
  font-size: 100%;
  color: #bbb;
  background-color: transparent;
}


//pre code {
//  padding: 0;
//  font-size: 100%;
//  color: inherit;
//  color: #333;
//  background-color: transparent;
//}

.blinking{
  animation:blinkingText 0.8s infinite;
}
@keyframes blinkingText{
  0%{   color: #fff;  }
  49%{  color: transparent; }
  50%{  color: transparent; }
  51%{  color:transparent;  }
  52%{ color: #fff;  }
  //100%{ color: #fff;  }
}


// I am going to try to put in blinking text

// Pygments/Rouge via Jekyll
.highlight {
  padding: .25rem;
  margin-bottom: 1rem;
  font-size: .8rem;
  line-height: 1.4;
  //background-color: $jupyter-bg;
  //background-color: $gray-code;
  background-color: $code-color;
  //background-color: #0A1547;
  //border: 1px solid $jupyter-border;
  //border: 1px solid #0A1547;
  border: 1px solid $code-color;
  border-radius: .25rem;

  pre {
    margin-bottom: 0;
    overflow-x: auto;
  }

  .lineno {
    display: inline-block; // Ensures the null space also isn't selectable
    padding-right: .75rem;
    padding-left: .25rem;
    color: #999;
    // Make sure numbers aren't selectable
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
  }
}

pre {
  max-width: 100%;
  overflow-x: auto;
}

// Gist via GitHub Pages
// .gist .gist-file {
//   font-family: Menlo, Monaco, "Courier New", monospace !important;
// }
// .gist .markdown-body {
//   padding: 15px;
// }
// .gist pre {
//   padding: 0;
//   background-color: transparent;
// }
// .gist .gist-file .gist-data {
//   font-size: .8rem !important;
//   line-height: 1.4;
// }
// .gist code {
//   padding: 0;
//   color: inherit;
//   background-color: transparent;
//   border-radius: 0;
// }
