///////////////////////////
//                       //
//       Colors          //
//                       //
///////////////////////////

//grey colors
$gray-1: #f9f9f9 !default;  //table brackground color is found in _base line 104
$gray-2: #E5E5E5 !default;
$gray-3: #ccc !default;
$gray-4: #767676 !default;
$gray-5: #515151 !default;
$gray-6: #313131 !default;
$jupyter-bg: #F7F7F7 !default;
$jupyter-border: #CECECE !default;
$side_bg_code_bg: #14151D !default;

//color for message and code
//$gray-code: #D1D1D1 !default;
$gray-code: #202020 !default;

$red: #ac4142 !default;
//$bracket_blue: #ac4142 !default;
$bracket_blue: #1e202c !default;

$orange: #d28445 !default;
$yellow: #f4bf75 !default;
$green: #90a959 !default;
$green2: #00AD28 !default;
$cyan: #75b5aa !default;
$blue: #268bd2 !default;
$bg_blue: #607DC7 !default;
$brown: #8f5536 !default;
$gold: #CA4 !default;
//$gold: #9C892A !default;
$gold_code: #F1D673 !default;


///////////////////////////
//                       //
//       Font            //
//                       //
///////////////////////////

$root-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", Arial, sans-serif !default;
$root-font-size: 1rem !default;
$root-line-height: 1.5 !default;

///////////////////////////
//                       //
//       Colors          //
//                       //
///////////////////////////

$body-color: $gray-5 !default;            //FONT COLOR
//$body-color: $gray-5 !default;
//$body-color: #27EE10FF !default;             DELETE
$body-muted: $gray-4 !default;
//$body-bg: #EEE !default;
//$body-bg: #232323 !default; 
$body-bg: #FFFFFFFF !default;                                //THIS is the background color. Go to layout, and then lines 121 for mobile, and 224 for desktop
//$body-bg: #2F2F2F !default; 
//THIS is the background color. Go to layout, and then lines 121 for mobile, and 224 for desktop
//$link-color: #CFB22F !default;
$link-color: $bg_blue !default;
//$link-color: $green2 !default;
$heading-color: #000 !default;
//$heading-color: $gray-6 !default;
//$code-color: #202020 !default;
$code-color: lighten($side_bg_code_bg, 5%) !default;
//$code-color: #45485D !default;

///////////////////////////
//                       //
//       Borders         //
//       Sidebar         //
//                       //
///////////////////////////

$border-color: $gray-2 !default;
$border-radius: 300px !default;
$padding-v: 1em !default;
$padding-h: 1.5em !default;
$heading-spacing: 0.5rem !default;
$section-spacing: 2rem !default;
//$sidebar-width: 18rem !default;
$sidebar-width: 19rem !default;             //width of sidebar

///////////////////////////
//                       //
//       Font            //
//                       //
///////////////////////////

$large-breakpoint: 49rem !default;
$large-font-size: 1.08rem !default;   //here is the font size

$layout_desktop_width: 55rem !default;

$box-shadow-size: 1px !default;
$box-shadow-opacity: 0.16 !default;
$default-box-shadow: $box-shadow-size
                     $box-shadow-size
                     $box-shadow-size
                     rgba(0, 0, 0, $box-shadow-opacity);

$code-font-family: Menlo, Monaco, "Courier New", monospace !default;
//$code-color: #441C20 !default;

// Hyde theming

//$sidebar-bg-color: #1E2945 !default;
$sidebar-bg-color: $side_bg_code_bg !default;
$sidebar-title-color: #ffffff !default;
$sidebar-sticky: true !default;
$layout-reverse: false !default;
//$bg-color: #F7F7F7 !default;
$bg-color: #FFFFFF !default;

//$sidebar-bg: "../../assets/img/sidebar-bg2.jpg" !default; 
//$sidebar-bg: "../../assets/img/wsu-red.png" !default;
//$sidebar-bg: "../../assets/img/wsu-tur.png" !default;			//where do i go to change 
//$sidebar-bg: "../../assets/img/wsu-gray.png" !default;			//where do i go to change 
//$sidebar-bg: "../../assets/img/wsu-dark2.png" !default;			//where do i go to change 
$sidebar-bg: "../../assets/img/wsu-dull-blue.png" !default;			//where do i go to change 
$sidebar-body-color: $gray-4 !default;
$active-link-color: #FFF !default;